<template>
  <div class="container">
    <v-row>
      <v-col cols="8" offset-lg="2" offset-md="2">
        <div class="d-flex flex-row flex-grow-1">
          <v-progress-circular
            v-if="listLoading"
            color="primary"
            indeterminate
            class="mt-4"
          />
          <div v-else class=" d-flex flex-column flex-grow-1">
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form
                ref="form"
                @submit.prevent="handleSubmit(assignAttendantToPumps)"
                class="px-3"
              >
                <v-card flat>
                  <Breadcrumbs
                    :items="[
                      {
                        text: 'Daily Activities',
                        disabled: false,
                        href: '/app/activities'
                      },
                      {
                        text: 'Edit Pump Assignation',
                        disabled: true,
                        href: ''
                      }
                    ]"
                  />
                  <v-card-title class="mt-n6">
                    <span class="font-weight-bold font blue-grey--text"
                      >Edit Assign Attendants To Pumps</span
                    >
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-col>
                      <v-row>
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row flex-grow-1"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            :nudge-left="10"
                            :nudge-top="30"
                            offset-y
                            min-width="auto"
                            class=" font font-weight-medium  "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-bind="attrs"
                                v-on="on"
                                v-model="attendantToPump.date"
                                outlined
                                class="font font-weight-medium"
                                readonly
                                disabled
                                label="Choose a Date"
                                :error="validateDate(attendantToPump.date)"
                                :error-messages="
                                  validateDate(attendantToPump.date)
                                    ? 'Date cannot be in the future'
                                    : errors[0]
                                "
                              />
                            </template>
                            <v-date-picker
                              class="font font-weight-medium  "
                              v-model="attendantToPump.date"
                              :max="maxDate | timestamp('YYYY-MM-DD')"
                              :min="minDate"
                              @input="searchRecords"
                            ></v-date-picker>
                          </v-menu>
                        </ValidationProvider>
                      </v-row>
                      <v-row>
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row flex-grow-1 font font-weight-medium"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            :items="pumps"
                            :error-messages="errors[0]"
                            item-value="id"
                            item-text="name"
                            class=" font font-weight-medium"
                            v-model="attendantToPump.pumpId"
                            outlined
                            label="Choose a pump"
                          />
                        </ValidationProvider>
                      </v-row>
                      <v-row>
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row flex-grow-1"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            :items="pumpAttendants"
                            item-value="id"
                            :error-messages="errors[0]"
                            v-model="attendantToPump.attendantId"
                            item-text="name"
                            class="font font-weight-medium"
                            label="Select one or more pump attendants"
                            small-chips
                            color="blue"
                            outlined
                          />
                        </ValidationProvider>
                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />

                    <v-btn
                      type="submit"
                      color="primary"
                      :loading="loading"
                      :disabled="validateDate(attendantToPump.date) || loading"
                      class=" font text-capitalize"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </ValidationObserver>
          </div>
        </div>
        <SnackBar
          :showSnackBar="showSnackBar"
          :status="status"
          :message="message"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import moment from "moment";
import { mapGetters } from "vuex";
import { timestamp } from "../../filters/timestamp";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { cloneDeep } from "lodash";
import SnackBarMixins from "../../mixins/SnackBarMixins";
extend("required", {
  ...required,
  message: "Field cannot be empty"
});
export default {
  name: "EditAttendantAssignedToPump",
  components: {
    ValidationProvider,
    ValidationObserver,
    Breadcrumbs: () => import(`../../components/Breadcrumbs`),
    SnackBar: () => import(`../../components/SnackBar`)
  },
  computed: {
    ...mapGetters({
      listLoading: "getIsListLoading",
      loading: "getIsLoading",
      settings: "settings/getSystemSettings",
      alreadyAssignedPumps: "pumps/getAlreadyAssignedPumps",
      pumpAssignAttendantDetails: "pumps/getPumpDetails"
    }),
    pumps: {
      get() {
        return this.$store.getters["pumps/getPumpList"];
      },
      set(value) {
        this.$store.commit("pumps/UPDATE_PUMPS", value);
      }
    },
    pumpAttendants: {
      get() {
        return this.$store.getters["pumps/getAllPumpAttendants"];
      },
      set(value) {
        this.$store.commit("pumps/UPDATE_PUMP_ATTENDANTS", value);
      }
    }
  },
  data() {
    return {
      date: "",
      menu: false,
      maxDate: new Date(),
      minDate: "",
      attendantToPump: {
        pumpId: "",
        date: "",
        attendantId: []
      }
    };
  },
  mixins: [SnackBarMixins],
  filters: { timestamp },
  watch: {
    selectedDate(value) {
      if (!value || value !== "") {
        this.attendantToPump.date = value;
      }
    },
    "attendantToPump.date"(date) {
      this.$store.dispatch("pumps/alreadyAssignedPumpsByDate", { date });
    },
    settings({ daysAllowedForRecordingFuelSales }) {
      this.minDate = moment()
        .subtract(daysAllowedForRecordingFuelSales, "days")
        .format("YYYY-MM-DD");
    },
    // eslint-disable-next-line no-unused-vars
    alreadyAssignedPumps(pumpAssignedAttendants) {
      const {
        pumpId: detailsPumpId,
        attendantId: detailsAttendantId
      } = this.pumpAssignAttendantDetails;
      const assignedAttendants = cloneDeep(pumpAssignedAttendants);
      const pumps = assignedAttendants
        .filter(({ pumpId }) => pumpId.id !== detailsPumpId.id)
        .map(({ pumpId }) => pumpId.id);
      const attendants = assignedAttendants
        .filter(({ attendantId }) => attendantId.id !== detailsAttendantId.id)
        .map(({ attendantId }) => attendantId.id);

      const filteredPumps = this.pumps.filter(({ id }) => !pumps.includes(id));
      const filteredAttendants = this.pumpAttendants.filter(
        ({ id }) => !attendants.includes(id)
      );
      this.pumps = filteredPumps;
      this.pumpAttendants = filteredAttendants;
    },
    pumpAssignAttendantDetails(details) {
      const { attendantId, dateAssigned, pumpId, id } = details;
      let payload = {
        id,
        attendantId: attendantId?.id ?? null,
        pumpId: pumpId?.id,
        date: dateAssigned
      };
      this.attendantToPump = { ...payload };
    }
  },
  methods: {
    searchRecords() {
      this.menu = false;
      this.$emit("searchRecords", this.attendantToPump.date);
    },
    validateDate(date) {
      return moment(date).isAfter(moment());
    },
    assignAttendantToPumps() {
      let payload = {
        ...this.attendantToPump,
        pumpId: [this.attendantToPump.pumpId]
      };
      this.$store.dispatch("pumps/updatePumpAssignAttendant", payload);
    }
  },
  created() {
    const { id } = this.$route.params;
    this.$store.dispatch("pumps/pumpAssignAttendantDetails", {
      id
    });
    this.$store.dispatch("pumps/allPumps");
    this.$store.dispatch("pumps/allPumpAttendants");
    this.$store.dispatch("settings/getSettings");
  }
};
</script>
